/* eslint-disable node/no-extraneous-import */
import fetch from 'isomorphic-fetch'
import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { hashPhrase } from '../lib/utils'

const httpLink = createHttpLink({
  uri:
    process.env.GATSBY_GRAPHQL ||
    process.env.GRAPHQL ||
    'https://dev.muddystilettos.co.uk/surrey/graphql',
  fetch
})

// const authLink = setContext((_, { headers }) => {
//   const token = localStorage.getItem('token')
//   return {
//     headers: {
//       ...headers,
//       authorization: token ? `Bearer ${token}` : ''
//     }
//   }
// })

const authLink = setContext((request, { headers }) => {
  // Define the operations that require authentication
  const authRequiredOperations = [
    'getUser',
    'UpdateUser',
    'SaveSchoolAwards',
    'SaveWomenInBusiness'
  ]

  const refererOps = ['voteBusiness']

  // Check if the current operation requires authentication
  const isAuthRequired = authRequiredOperations.includes(request.operationName)
  const isReferer = refererOps.includes(request.operationName)

  // Get the token only if authentication is required
  const token = isAuthRequired ? localStorage.getItem('token') : null

  const referer = hashPhrase('muddystilettos.valid')

  return {
    headers: {
      ...headers,
      ...(isReferer ? { 'X-JWT-Refresh': referer } : {}),
      ...(token ? { authorization: `Bearer ${token}` } : {})
    }
  }
})

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: authLink.concat(httpLink)
})

export default client
