// eslint-disable-next-line node/no-extraneous-import
import { gql } from '@apollo/client'
import { User } from '../../types'

const GetUserQuery = gql`
  query getUser {
    viewer {
      name
      email
    }
  }
`

export interface IGetUserQueryData {
  viewer: User
}

export default GetUserQuery
