import React, { ChangeEvent, ReactNode, createContext, useReducer } from 'react'
import { AwardsCategory, LiveVoteDataSingle } from '../types'
import CryptoJS from 'crypto-js'
import { IVotingData } from '../components/MuddyAwards/MartiniGlass/MartiniGlass'
import { SchoolGoogleSheetData } from '../types'
import { EventEmitter } from 'events'
import _ from 'lodash'
import { FormFields } from '../components/BestSchoolsAwards/Form/Form'
import { signupToDebretts } from '../api/best-school-awards/debretts'

export const hashEmail = (emailAddress: string) => {
  const passphrase = 'ASD-DAS_ASD-KKKKO0SQ3-FFF'
  return CryptoJS.AES.encrypt(emailAddress.toLowerCase(), passphrase).toString()
}

function shiftString(str: string, offset: number) {
  return str.replace(/[a-z]/g, function (char) {
    // Calculate the new character code, shifted by 2
    let shiftedCode = char.charCodeAt(0) + offset
    // Wrap around from 'z' to 'a'
    if (shiftedCode > 'z'.charCodeAt(0)) {
      shiftedCode -= 26
    }
    return String.fromCharCode(shiftedCode)
  })
}

export const hashPhrase = (phraseRaw: string) => {
  const passphrase = 'ASD-DAS_ASD-KKKKO0SQ3-FFF'

  const random = Math.floor(Math.random() * 3) + 1

  const phrase = shiftString(phraseRaw, random)

  const phrases = [
    '1' + phrase.replace('.', 'X') + passphrase + Date.now(),
    '2' + Date.now() + phrase.replace('.', 'e') + passphrase,
    '3' + passphrase + Date.now() + phrase.replace('.', 'g')
  ]

  const buf = `${random}${btoa(phrases[random - 1])}`
  return buf
}

export const unhashEmail = (ciphertext: string) => {
  const passphrase = 'ASD-DAS_ASD-KKKKO0SQ3-FFF'
  const bytes = CryptoJS.AES.decrypt(ciphertext, passphrase)
  const originalText = bytes.toString(CryptoJS.enc.Utf8)
  return originalText
}

export const formatDate = (date: string) => {
  const d = new Date(date)
  const month = d.toLocaleString('default', { month: 'long' })
  return d.getDate() + ' ' + month + ' ' + d.getFullYear()
}

export const getUrlParams = (search: string) => {
  const hashes = search.slice(search.indexOf('?') + 1).split('&')
  return hashes.reduce((params, hash) => {
    const [key, val] = hash.split('=')
    return Object.assign(params, { [key]: decodeURIComponent(val) })
  }, {})
}

/* Temporary fix until URL structure is corrected */

export const fixURL = (url: string) => {
  const subDomain = url.split('.')[0].replace('https://', '')
  return url.replace(`.co.uk/`, `.co.uk/${subDomain}/`)
}

export const stripCounty = (path: string, siteId?: number | null) => {
  const directory = path.split('/')[1]

  return siteId === 1 ? path : path.replace(`/${directory}/`, `/`)
}

export const fixAmpersands = (siteName: string | null) => {
  return siteName ? siteName.replace('&amp;', '&') : null
}

export interface IViewer {
  viewer: {
    __typename: 'User'
    name: string
    email?: string
    jwtRefreshToken?: string
  }
}
export interface ILoginContext {
  usr?: IViewer
  setUsr?: React.Dispatch<React.SetStateAction<Record<string, unknown>>>
  loading: boolean
}
export const LoginContext = createContext<ILoginContext>({
  loading: false
})

export enum BPTLStates {
  CLOSED = 'closed',
  REGIONAL = 'regional',
  REGIONAL_CLOSED = 'regional-closed',
  NATIONAL = 'national',
  NATIONAL_CLOSED = 'national-closed'
}

interface Result {
  [category: string]: {
    votes: number
    label: string
  }
}

export const parseRawData = (
  data: LiveVoteDataSingle,
  categoryObject: AwardsCategory
) => {
  const { finalists } = categoryObject
  let totalVotes = 0
  const parsed: Result = {}

  if (finalists) {
    Object.keys(finalists).forEach(property => {
      if (!(property in data)) {
        ;(data as any)[property] = 0
      }
    })

    const orderedData = _.fromPairs(_.orderBy(_.toPairs(data), [0], ['asc']))

    Object.entries(orderedData).forEach(([property, count]) => {
      if (property.startsWith('meta_')) {
        const label = finalists[property as keyof typeof finalists]
        if (label) {
          totalVotes += count
          const category = property.replace('meta_', '')
          if (!parsed[category]) {
            parsed[category] = {
              votes: count,
              label: label
            }
          } else {
            parsed[category].votes += count
          }
        }
      }
    })
  }

  let currentCount = 0

  const reduced: IVotingData = Object.entries(parsed).reduce(
    (result, [key, value]) => {
      result[key as keyof IVotingData] = {
        label: value.label,
        top: currentCount,
        percentage: (value.votes / totalVotes) * 100
      }

      currentCount = currentCount + (value.votes / totalVotes) * 100
      return result
    },
    {} as IVotingData
  )

  return reduced
}
export const BPTLContext = createContext<BPTLStates>(BPTLStates.CLOSED)

export enum MuddyAwardsStates {
  NOMINATIONS = 'nominations',
  NOMINATIONS_CLOSED = 'nominationsClosed',
  VOTING = 'voting',
  VOTING_CLOSED = 'votingClosed',
  WINNERS = 'winners',
  NATIONAL_FINALISTS = 'nationalFinalists',
  NATIONAL_WINNERS = 'nationalWinners'
}

export const MuddyAwardsContext = createContext<MuddyAwardsStates>(
  MuddyAwardsStates.NOMINATIONS
)

export const MuddyAwardsStatesOrder: MuddyAwardsStates[] = [
  MuddyAwardsStates.NOMINATIONS,
  MuddyAwardsStates.NOMINATIONS_CLOSED,
  MuddyAwardsStates.VOTING,
  MuddyAwardsStates.VOTING_CLOSED,
  MuddyAwardsStates.WINNERS,
  MuddyAwardsStates.NATIONAL_FINALISTS,
  MuddyAwardsStates.NATIONAL_WINNERS
]

export const AwardsStateComparison = (
  currentState: MuddyAwardsStates,
  targetState: MuddyAwardsStates,
  operator: '>' | '<' | '>=' | '<=' | '===' = '>'
): boolean => {
  const currentIndex = MuddyAwardsStatesOrder.indexOf(currentState)
  const targetIndex = MuddyAwardsStatesOrder.indexOf(targetState)

  switch (operator) {
    case '>':
      return currentIndex > targetIndex
    case '<':
      return currentIndex < targetIndex
    case '>=':
      return currentIndex >= targetIndex
    case '<=':
      return currentIndex <= targetIndex
    case '===':
      return currentIndex === targetIndex
    default:
      throw new Error(`Invalid operator: ${operator}`)
  }
}

// Define action types
type AditionSlotsAction =
  | { type: 'SET_ADITION_SLOTS'; payload: string[] }
  | { type: 'ADD_RENDERED_SLOT'; payload: string[] }

const aditionSlotsReducer = (
  state: IAditionContext,
  action: AditionSlotsAction
): IAditionContext => {
  switch (action.type) {
    case 'SET_ADITION_SLOTS':
      const newAditionSlots = state.aditionSlots
        ? [...new Set([...state.aditionSlots, ...action.payload])]
        : [...new Set(action.payload)]

      return {
        ...state,
        aditionSlots: newAditionSlots
      }

    case 'ADD_RENDERED_SLOT':
      const newRenderedSlots = state.renderedSlots
        ? [...new Set([...state.renderedSlots, ...action.payload])]
        : [...new Set(action.payload)]

      return {
        ...state,
        renderedSlots: newRenderedSlots
      }

    default:
      return state
  }
}

const initialAditionSlotsState: IAditionContext = {
  aditionSlots: [],
  renderedSlots: []
}

interface IAditionContext {
  aditionSlots?: string[]
  renderedSlots?: string[]
  dispatch?: React.Dispatch<AditionSlotsAction>
}

// Your context definition
export const AditionSlotsContext = createContext<IAditionContext>({})

// AditionSlotsContextProvider component
interface AditionSlotsContextProviderProps {
  children: ReactNode
}

export const AditionSlotsContextProvider: React.FC<
  AditionSlotsContextProviderProps
> = ({ children }) => {
  const [aditionSlotsState, dispatch] = useReducer(
    aditionSlotsReducer,
    initialAditionSlotsState
  )

  const contextValue: IAditionContext = {
    aditionSlots: aditionSlotsState.aditionSlots,
    renderedSlots: aditionSlotsState.renderedSlots, // Include the renderedSlots array
    dispatch
  }

  return React.createElement(
    AditionSlotsContext.Provider,
    { value: contextValue },
    children
  )
}

export const simplifySchoolData = (data: SchoolGoogleSheetData) => {
  return data.allGoogleSheetAllSchoolsRow?.edges.map(edge => ({
    schoolid: edge.node.schoolid,
    name: edge.node.name
  }))
}

export const handleFormChange = (
  event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
) => {
  return {
    name: event.target.name,
    value: event.target.value
  }
}

export const headerEvents = new EventEmitter()
export const schoolAwardsCheckSubmittedEntries = async (
  formData: FormFields
) => {
  try {
    const entryData = new FormData()
    entryData.append('schoolId', `${formData.schoolid}`)
    const url =
      'https://dev.muddystilettos.co.uk/wp-json/muddy/school-entry-number/'
    const response = await fetch(url, {
      method: 'post',
      body: entryData
    })
    const json = await response.json()
    return json
  } catch (e) {
    return { success: 0, message: e }
  }
}

export const makePayment = async ({
  entryId,
  formData,
  amount,
  paymentIntent,
  paymentId
}: {
  entryId: string | number
  formData: FormFields
  amount: number
  paymentIntent: string
  paymentId: string
}) => {
  try {
    const paymentData = new FormData()
    paymentData.append('entryid', `${entryId}`)
    paymentData.append('amount', `${amount}`)
    paymentData.append('paymentIntent', `${paymentIntent}`)
    paymentData.append('paymentid', `${paymentId}`)
    Object.entries(formData).forEach(([key, value]) => {
      paymentData.append(key, `${value}`)
    })
    const url = 'https://dev.muddystilettos.co.uk/wp-json/muddy/make-payment/'
    const response = await fetch(url, {
      method: 'POST',
      body: paymentData
    })
    const json = await response.json()
    return json
  } catch (e) {
    console.log(e)
  }
}

export const getPaymentIntent = async (credits: string) => {
  try {
    const paymentData = new FormData()
    paymentData.append('amount', credits)
    const url = 'https://dev.muddystilettos.co.uk/wp-json/muddy/payment-intent/'
    const response = await fetch(url, {
      method: 'post',
      body: paymentData
    })
    const json = await response.json()
    return json
  } catch (e) {
    console.log(e)
  }
}

export const singleEntry = async (id: string) => {
  try {
    const entryData = new FormData()
    entryData.append('entryid', id)
    const url = 'https://dev.muddystilettos.co.uk/wp-json/muddy/school-entry/'
    const response = await fetch(url, {
      method: 'post',
      body: entryData
    })
    const json = await response.json()
    return json
  } catch (e) {
    console.log(e)
  }
}

export const schoolEntries = async (id: string) => {
  try {
    const entryData = new FormData()
    entryData.append('schoolId', id)
    const url =
      'https://dev.muddystilettos.co.uk/wp-json/muddy/school-entry-number/'
    const response = await fetch(url, {
      method: 'post',
      body: entryData
    })
    const json = await response.json()
    return json
  } catch (e) {
    console.log(e)
  }
}

export const debrettsSignup = async (email: string) => {
  await signupToDebretts(email)
}

export enum SchoolAwardsStates {
  OPEN = 'open',
  CLOSED = 'closed',
  FINALISTS = 'finalists',
  WINNERS = 'winners'
}

export const SchoolAwardsContext = createContext<SchoolAwardsStates>(
  SchoolAwardsStates.OPEN
)
