export const signupToDebretts = async (email: string) => {
  const requestOptions = {
    method: 'POST', // You may want to change this to 'GET' or another method if needed
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ email }) // Pass the userEmail variable in the request body
  }
  try {
    const url = '/.netlify/functions/debrettsSignup'
    const res = await fetch(url, requestOptions)
    const json = await res.json()
    console.log('json', json)
    return json
  } catch (e: any) {
    return { success: 0, message: e.message }
  }
}
