import React, { useMemo, useState, useEffect } from 'react'
import { useQuery } from '@apollo/client'
// import Maintenance from './src/components/layoutComponents/maintenance/Maintenance'
import {
  LoginContext,
  BPTLStates,
  BPTLContext,
  MuddyAwardsStates,
  MuddyAwardsContext,
  AditionSlotsContextProvider,
  SchoolAwardsContext,
  SchoolAwardsStates
} from './src/lib/utils'
import GetUserQuery from './src/graphql/queries/GetUserQuery'
import moment from 'moment'

export const wrapPageElement = ({ element, props }) => {
  // if (moment() > moment('20240722124500', 'YYYYMMDDHHmmss'))
  //   return <Maintenance />

  const [usr, setUsr] = useState({})
  const [loading, setLoading] = useState(true)
  const [bptlState, setBptlState] = useState(BPTLStates.CLOSED)
  const [awardsState, setAwardsState] = useState(MuddyAwardsStates.NOMINATIONS)
  const [schoolsState, setSchoolsState] = useState(SchoolAwardsStates.OPEN)

  const { data: rawUser, loading: queryLoading } = useQuery(GetUserQuery)

  useEffect(() => {
    setLoading(queryLoading)
  }, [queryLoading])

  const user = useMemo(
    () => ({
      usr,
      setUsr,
      loading
    }),
    [usr]
  )

  useEffect(() => {
    setUsr(rawUser)
  }, [rawUser])

  useEffect(() => {
    const now = moment()
    const times = {
      regional: moment('20240801080000', 'YYYYMMDDHHmmss'),
      // regionalClosed: moment('20230310163000', 'YYYYMMDDHHmmss'),
      regionalClosed: moment('20240815235959', 'YYYYMMDDHHmmss'),
      national: moment('20250308070000', 'YYYYMMDDHHmmss'),
      nationalClosed: moment('20250322050000', 'YYYYMMDDHHmmss')
    }

    const awardTimes = {
      nominate: moment('20240201080000', 'YYYYMMDDHHmmss'),
      nominateClosed: moment('20240314130000', 'YYYYMMDDHHmmss'),
      voting: moment('20240403120000', 'YYYYMMDDHHmmss'),
      votingClosed: moment('20240418130000', 'YYYYMMDDHHmmss'),
      winners: moment('202405021130000', 'YYYYMMDDHHmmss'),
      nationalFinalists: moment('20240703120000', 'YYYYMMDDHHmmss'),
      nationalWinners: moment('20240711120000', 'YYYYMMDDHHmmss')
    }

    const schoolTimes = {
      open: moment('20240101080000', 'YYYYMMDDHHmmss'),
      closed: moment('20240423150000', 'YYYYMMDDHHmmss'),
      finalists: moment('20240620133000', 'YYYYMMDDHHmmss'),
      winners: moment('202406271030000', 'YYYYMMDDHHmmss')
    }

    let correctState, correctAwardsState, correctSchoolState

    Object.keys(times).forEach(time => {
      if (now.isAfter(times[time])) {
        correctState = time
      }
    })

    Object.keys(awardTimes).forEach(time => {
      if (now.isAfter(awardTimes[time])) {
        correctAwardsState = time
      }
    })

    Object.keys(schoolTimes).forEach(time => {
      if (now.isAfter(schoolTimes[time])) {
        correctSchoolState = time
      }
    })

    switch (correctState) {
      case 'regional':
        setBptlState(BPTLStates.REGIONAL)
        break

      case 'regionalClosed':
        setBptlState(BPTLStates.REGIONAL_CLOSED)
        break

      case 'national':
        setBptlState(BPTLStates.NATIONAL)
        break

      case 'nationalClosed':
        setBptlState(BPTLStates.NATIONAL_CLOSED)
        break

      default:
        setBptlState(BPTLStates.CLOSED)
        break
    }

    switch (correctAwardsState) {
      case 'nominate':
        setAwardsState(MuddyAwardsStates.NOMINATIONS)
        break

      case 'nominateClosed':
        setAwardsState(MuddyAwardsStates.NOMINATIONS_CLOSED)
        break

      case 'voting':
        setAwardsState(MuddyAwardsStates.VOTING)
        break

      case 'votingClosed':
        setAwardsState(MuddyAwardsStates.VOTING_CLOSED)
        break

      case 'winners':
        setAwardsState(MuddyAwardsStates.WINNERS)
        break

      case 'nationalFinalists':
        setAwardsState(MuddyAwardsStates.NATIONAL_FINALISTS)
        break

      case 'nationalWinners':
        setAwardsState(MuddyAwardsStates.NATIONAL_WINNERS)
        break

      default:
        setAwardsState(MuddyAwardsStates.NOMINATIONS)
        break
    }

    switch (correctSchoolState) {
      case 'open':
        setSchoolsState(SchoolAwardsStates.OPEN)
        break

      case 'closed':
        setSchoolsState(SchoolAwardsStates.CLOSED)
        break

      case 'finalists':
        setSchoolsState(SchoolAwardsStates.FINALISTS)
        break

      case 'winners':
        setSchoolsState(SchoolAwardsStates.WINNERS)
        break

      default:
        setSchoolsState(SchoolAwardsStates.OPEN)
        break
    }

    /* If we want to test a specific state, we can do so by setting the state here */
    if (typeof window !== 'undefined') {
      // eslint-disable-next-line node/no-unsupported-features/node-builtins
      const urlParams = new URLSearchParams(window.location.search)
      const bptlStateParam = urlParams.get('bptl')
      const muddyAwardsStateParam = urlParams.get('muddyawards')
      const schoolAwards = urlParams.get('schoolawards')

      if (bptlStateParam) {
        setBptlState(bptlStateParam)
      }
      if (muddyAwardsStateParam) {
        setAwardsState(muddyAwardsStateParam)
      }
      if (schoolAwards) {
        setSchoolsState(schoolAwards)
      }
    }
  }, [])

  return (
    <AditionSlotsContextProvider>
      <BPTLContext.Provider value={bptlState} {...props}>
        <SchoolAwardsContext.Provider value={schoolsState} {...props}>
          <MuddyAwardsContext.Provider value={awardsState} {...props}>
            <LoginContext.Provider value={user} {...props}>
              {element}
            </LoginContext.Provider>
          </MuddyAwardsContext.Provider>
        </SchoolAwardsContext.Provider>
      </BPTLContext.Provider>
    </AditionSlotsContextProvider>
  )
}
